import { PropsWithChildren, useEffect, useRef } from "react";
import { FeatureGroup, useMap } from "react-leaflet";

interface FitBoundsProps extends PropsWithChildren {
  padding?: number;
}

export function FitBounds({ padding = 40, children }: FitBoundsProps) {
  const map = useMap();

  const featureGroupRef = useRef(null);

  const currentBounds = useRef(null);

  useEffect(() => {
    if (featureGroupRef.current) {
      // @ts-ignore
      const bounds = featureGroupRef.current.getBounds();
      if (bounds._northEast && !bounds.equals(currentBounds.current)) {
        map.fitBounds(bounds, { padding: [padding, padding] });
        currentBounds.current = bounds;
      }
    }
  }, [map, featureGroupRef, padding, children]);

  return <FeatureGroup ref={featureGroupRef}>{children}</FeatureGroup>;
}
