import { useInterval } from "usehooks-ts";
import { useState } from "react";

export function useCacheBuster(interval: number) {
  const [timestamp, setTimestamp] = useState(Date.now());

  const refresh = () => setTimestamp(Date.now());

  useInterval(refresh, interval);

  return { cacheBuster: timestamp, refresh };
}
