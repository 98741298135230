import { Stop } from "../hooks/useRoute";
import { StopLink } from "./StopLink";

interface StopsListProps {
  stops: Stop[];
  directions: string[];
}

export function StopsList({ stops, directions }: StopsListProps) {
  return (
    <ul>
      {stops.map((stop) => (
        <li key={stop.stop_id}>
          <StopLink
            stopName={stop.stop_name}
            bold={directions.includes(stop.stop_name)}
            link
          />
        </li>
      ))}
    </ul>
  );
}
