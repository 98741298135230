import { Category } from "../../hooks/useActiveVehicles";

interface VehicleIconProps {
  category: Category;
  arrow?: boolean;
  outdated?: boolean;
}

export function VehicleIcon({
  category,
  arrow = false,
  outdated = false,
}: VehicleIconProps) {
  const color = outdated
    ? "rgba(0,0,0,0.2)"
    : category === "tram"
    ? "red"
    : category === "bus"
    ? "blue"
    : category === "mobilis"
    ? "navy"
    : "orange";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100}>
      {category === "tram" ? (
        <rect
          x={20}
          y={20}
          width={60}
          height={60}
          stroke={color}
          strokeWidth={2.5}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
        />
      ) : (
        <circle
          cx={50}
          cy={50}
          r={130 / 4}
          stroke={color}
          strokeWidth={2.5}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
        />
      )}
      {arrow && (
        <polygon points="50,0 65,15 35,15" fill={color} fillOpacity={0.5} />
      )}
    </svg>
  );
}
