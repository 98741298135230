import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import "./instrument";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "./components/Fallback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<Fallback />} showDialog>
      <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
