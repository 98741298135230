import "./Map2.css";

import { featureCollection, lineString, point } from "@turf/helpers";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useMemo, useRef, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  LngLat,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
} from "react-map-gl/maplibre";
import { KmkId } from "../components/KmkId";
import { Navigation } from "../components/Navigation";
import PageTitle from "../components/PageTitle";
import {
  Source as SourceType,
  useActiveVehicles,
  Vehicle,
} from "../hooks/useActiveVehicles";
import { useStopCentroids } from "../hooks/useStopCentroids";
import { useStopPoints2 } from "../hooks/useStopPoints2";
import { Link } from "react-router-dom";
import { StopLink } from "../components/StopLink";
import { Counter } from "../components/Counter";
import { getFullSourceName } from "../utils";
import { useTicketZones } from "../hooks/useTicketZones";
import {
  ColorSpecification,
  DataDrivenPropertyValueSpecification,
} from "maplibre-gl";
import { usePath } from "../hooks/usePath";
import { useMapIcons } from "../hooks/useMapIcons";

function VehicleMarkers() {
  const { vehicles } = useActiveVehicles("gtfs" as SourceType, 10_000);

  useMapIcons();

  const [geojson, outdatedThreshold] = useMemo(() => {
    return [
      featureCollection(
        vehicles
          .filter((v) => v.latitude !== null && v.longitude !== null)
          .map((v) => point([v.longitude!, v.latitude!], v))
      ),
      Date.now() / 1000 - 7 * 60, // 7 min
    ];
  }, [vehicles]);

  return (
    <Source id="vehicles" type="geojson" data={geojson}>
      <Layer
        id="vehicles"
        type="symbol"
        layout={{
          "text-field": [
            "case",
            [
              "all",
              ["==", ["get", "trip_headsign"], "Zajezdnia Nowa Huta"],
              ["!=", ["get", "route_short_name"], "4"],
              ["!=", ["get", "route_short_name"], "22"],
              ["!=", ["get", "route_short_name"], "601"],
            ],
            "ZNH",
            ["==", ["get", "trip_headsign"], "PT"],
            "ZP",
            ["get", "route_short_name"],
          ],
          "text-font": ["Open Sans Bold"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 6, 10, 15, 16],
          "text-allow-overlap": true,
          "icon-image": [
            "concat",
            ["get", "category"],
            [
              "case",
              ["!=", ["to-string", ["get", "bearing"]], ""],
              "-arrow",
              "",
            ],
            [
              "case",
              ["<=", ["number", ["get", "timestamp"]], outdatedThreshold],
              "-outdated",
              "",
            ],
          ],
          "icon-size": ["interpolate", ["linear"], ["zoom"], 6, 0.25, 15, 0.45],
          "icon-rotate": ["get", "bearing"],
          "icon-allow-overlap": true,
        }}
        paint={{
          "text-color": "white",
          "icon-opacity": [
            "case",
            [
              "in",
              ["get", "trip_headsign"],
              [
                "literal",
                ["Wyjazd na linię", "Zjazd do zajezdni", "Przejazd techniczny"],
              ],
            ],
            0.4,
            1,
          ],
          "text-halo-color": [
            "case",
            ["<=", ["number", ["get", "timestamp"]], outdatedThreshold],
            "rgba(0,0,0,0.2)",
            [
              "match",
              ["get", "category"],
              "tram",
              "rgba(255,0,0,0.5)",
              "bus",
              "rgba(0,0,255,0.5)",
              "mobilis",
              "rgba(0,0,128,0.5)",
              "rgba(255,128,0,0.5)",
            ],
          ],
          "text-halo-width": 0.5,
        }}
      />
    </Source>
  );
}

function StopMarkers() {
  const { stopCentroids } = useStopCentroids();

  if (stopCentroids === null) {
    return null;
  }

  return (
    <Source id="stops" type="geojson" data={stopCentroids}>
      <Layer
        id="stops_circles"
        type="circle"
        paint={{
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 1, 15, 3],
          "circle-color": "black",
          "circle-opacity": 0.5,
        }}
      />
      <Layer
        id="stops_labels"
        type="symbol"
        minzoom={14}
        layout={{
          "text-field": ["get", "Zespół"],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": "gray",
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

const stopPointColorExpression = [
  "match",
  ["get", "Typ_przystanku"],
  "T",
  "red",
  "A",
  "blue",
  "TA",
  "magenta",
  "pT",
  "gray",
  "pA",
  "gray",
  "tymT",
  "red",
  "tymA",
  "blue",
  "limegreen",
] as DataDrivenPropertyValueSpecification<string>;

function StopPointsMarkers() {
  const { stopPoints } = useStopPoints2();

  if (stopPoints === null) {
    return null;
  }

  return (
    <Source id="stop_points" type="geojson" data={stopPoints}>
      <Layer
        id="stops_points_outlines"
        type="circle"
        minzoom={15}
        paint={{
          "circle-radius": 9,
          "circle-color": "white",
        }}
      />
      <Layer
        id="stops_points_circles"
        type="circle"
        minzoom={15}
        paint={{
          "circle-radius": 7,
          "circle-color": "transparent",
          "circle-stroke-width": 1.5,
          "circle-stroke-color": stopPointColorExpression,
        }}
      />
      <Layer
        id="stops_points_letters"
        type="symbol"
        minzoom={15}
        layout={{
          "text-field": ["get", "Typ_przystanku"],
          "text-font": ["Open Sans Bold"],
          "text-size": 11,
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": stopPointColorExpression,
        }}
      />
      <Layer
        id="stops_points_labels"
        type="symbol"
        minzoom={16}
        layout={{
          "text-field": ["slice", ["get", "kod_busman"], -2],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "left",
          "text-offset": [0.85, 0],
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": stopPointColorExpression,
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

const ticketZonesColorExpression = [
  "match",
  ["get", "Strefa"],
  "I",
  "dodgerblue",
  "II",
  "limegreen",
  "III",
  "goldenrod",
  "tomato",
] as DataDrivenPropertyValueSpecification<ColorSpecification>;

function TicketZonesPolygons() {
  const { ticketZones } = useTicketZones();

  if (ticketZones === null) {
    return null;
  }

  return (
    <Source id="ticket-zones" type="geojson" data={ticketZones}>
      <Layer
        id="ticket_zones_fill"
        type="fill"
        maxzoom={11}
        paint={{
          "fill-color": ticketZonesColorExpression,
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 9, 0.2, 11, 0],
        }}
      />
      <Layer
        id="ticket_zones_lines"
        type="line"
        maxzoom={13}
        paint={{
          "line-color": ticketZonesColorExpression,
          "line-width": 1.5,
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0.3, 13, 0],
        }}
      />
      <Layer
        id="ticket_zones_labels"
        type="symbol"
        maxzoom={13}
        layout={{
          "text-field": ["get", "Nazwa"],
          "text-font": ["Open Sans Italic"],
          "text-size": 11,
          "text-allow-overlap": true,
          "icon-allow-overlap": true,
        }}
        paint={{
          "text-color": "gray",
          "text-opacity": ["interpolate", ["linear"], ["zoom"], 11, 1, 13, 0],
        }}
      />
    </Source>
  );
}

function getDistanceBetweenCoords(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const R = 6_371_000;
  const dLat = deg2rad(lat2 - lat1);
  const dLng = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getNearestFeatureProperties<T>(
  features: MapGeoJSONFeature[] | undefined,
  lngLat: LngLat
): T | null {
  if (!features || features.length === 0) {
    return null;
  }

  return features.reduce((prev, curr) =>
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      curr.properties.latitude,
      curr.properties.longitude
    ) <
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      prev.properties.latitude,
      prev.properties.longitude
    )
      ? curr
      : prev
  ).properties as T;
}

interface HoveredVehiclePopupProps {
  vehicle: Vehicle | null;
}

function HoveredVehiclePopup({ vehicle }: HoveredVehiclePopupProps) {
  if (
    vehicle === null ||
    vehicle.latitude === null ||
    vehicle.longitude === null
  ) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude!}
      longitude={vehicle.longitude!}
      anchor="bottom"
      offset={[0, -10] as [number, number]}
      className="hovered-vehicle-popup bold"
      closeButton={false}
      key={`${vehicle.key}_hover`}
    >
      <KmkId
        kmk_id={vehicle.full_kmk_id ?? "?????"}
        link={!!vehicle.full_kmk_id}
      />
    </Popup>
  );
}

interface ClickedVehiclePopupProps {
  vehicle: Vehicle | null;
}

function ClickedVehiclePopup({ vehicle }: ClickedVehiclePopupProps) {
  if (
    vehicle === null ||
    vehicle.latitude === null ||
    vehicle.longitude === null
  ) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude}
      longitude={vehicle.longitude}
      anchor="bottom"
      maxWidth="auto"
      offset={[0, -10] as [number, number]}
      className="clicked-vehicle-popup"
      closeButton={false}
      key={`${vehicle.key}_click`}
    >
      {vehicle.route_short_name !== null && (
        <>
          Linia:{" "}
          <strong>
            <Link
              to={`/routes/${vehicle.route_short_name}`}
              className="hidden-link"
            >
              {vehicle.route_short_name}
            </Link>
          </strong>
          <br />
        </>
      )}
      {vehicle.trip_headsign !== null && vehicle.trip_headsign !== undefined && (
        <>
          Kierunek:{" "}
          <StopLink
            stopName={vehicle.trip_headsign}
            bold
            expandDepotName
            removeNz
          />
          <br />
        </>
      )}
      {vehicle.full_kmk_id !== null && vehicle.full_kmk_id !== undefined && (
        <>
          Numer taborowy:{" "}
          <strong>
            <KmkId kmk_id={vehicle.full_kmk_id} />
          </strong>
          <br />
        </>
      )}
      {vehicle.shift !== undefined &&
        vehicle.shift !== vehicle.route_short_name && (
          <>
            Brygada: <strong>{vehicle.shift}</strong>
            <br />
          </>
        )}
      <small>
        {vehicle.timestamp !== null && (
          <>
            <Counter timestamp={vehicle.timestamp} /> |{" "}
          </>
        )}
        {getFullSourceName(vehicle.source)}
      </small>
    </Popup>
  );
}

interface ClickedVehiclePathPolylineProps {
  vehicle: Vehicle;
}

function ClickedVehiclePathPolyline({
  vehicle,
}: ClickedVehiclePathPolylineProps) {
  // TODO: improve path fetching
  const { path, loading, error } = usePath(
    vehicle.category,
    vehicle.route_short_name ?? "",
    vehicle.trip_headsign ?? ""
  );

  const geojson = useMemo(() => {
    if (loading || error || path.length === 0) {
      return null;
    }
    return lineString(path.map((p) => [p.longitude, p.latitude]));
  }, [path, error, loading]);

  if (geojson === null) {
    return null;
  }

  const color =
    vehicle.category === "tram"
      ? "red"
      : vehicle.category === "bus"
      ? "blue"
      : vehicle.category === "mobilis"
      ? "navy"
      : "orange";

  return (
    <Source id="clicked-vehicle-path" type="geojson" data={geojson}>
      <Layer
        id="clicked_vehicle_path"
        type="line"
        layout={{
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-color": color,
          "line-width": 5,
          "line-opacity": 0.5,
        }}
      />
    </Source>
  );
}

export function Map2() {
  const mapRef = useRef(null);

  const [clickedVehicle, setClickedVehicle] = useState<Vehicle | null>(null);

  const [hoveredVehicle, setHoveredVehicle] = useState<Vehicle | null>(null);

  const onMouseMove = useCallback((event: MapLayerMouseEvent) => {
    // TODO: move popup on position change
    setHoveredVehicle(
      getNearestFeatureProperties<Vehicle>(event.features, event.lngLat)
    );
  }, []);

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    // TODO: move popup on position change
    const maybeFeature = getNearestFeatureProperties<Vehicle>(
      event.features,
      event.lngLat
    );
    setClickedVehicle(maybeFeature);
    if (maybeFeature) {
      // @ts-expect-error
      mapRef.current?.flyTo({
        center: [maybeFeature.longitude, maybeFeature.latitude],
        speed: 0.7,
      });
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageTitle title="Mapa" />
      <Navigation />
      <Map
        initialViewState={{
          latitude: 50.06,
          longitude: 19.94,
          zoom: 13.1,
        }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        onMouseMove={onMouseMove}
        onClick={onClick}
        interactiveLayerIds={["vehicles"]}
        ref={mapRef}
      >
        <TicketZonesPolygons />
        <StopMarkers />
        <StopPointsMarkers />
        <VehicleMarkers />
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ScaleControl />
        <HoveredVehiclePopup vehicle={hoveredVehicle} />
        <ClickedVehiclePopup vehicle={clickedVehicle} />
        {clickedVehicle && (
          <ClickedVehiclePathPolyline vehicle={clickedVehicle} />
        )}
      </Map>
    </div>
  );
}
