import { Coords, useNearStops } from "../hooks/useNearStops";
import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { Error } from "../components/Error";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { getStopUrl } from "../utils";
import Container from "react-bootstrap/Container";

interface NearStopsInnerProps {
  coords: Coords;
}

function NearStopsInner({ coords }: NearStopsInnerProps) {
  const { stops, loading, error } = useNearStops(coords);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <>
      {stops.map((stop) => (
        <React.Fragment key={stop.stop_name}>
          <Link to={getStopUrl(stop.stop_name)}>
            <Button variant="outline-primary" className="mb-4">
              {stop.stop_name}
            </Button>
          </Link>
          <br />
        </React.Fragment>
      ))}
    </>
  );
}

function NearStops() {
  const [state, setState] = useState<string>();
  const [requested, setRequested] = useState(false);
  const [coords, setCoords] = useState<Coords>();

  useEffect(() => {
    let ignore = false;
    (async () => {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (!ignore) {
          setState(result.state);
        }
      });
    })();
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;
    if (requested || state === "granted") {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!ignore) {
          setCoords(position.coords);
        }
      });
    }
    return () => {
      ignore = true;
    };
  }, [requested, state]);

  if (coords !== undefined) {
    return <NearStopsInner coords={coords} />;
  }

  if (state === "prompt") {
    return (
      <Button
        variant="outline-primary"
        className="mb-4"
        onClick={() => setRequested(true)}
      >
        Znajdź przystanki w pobliżu
      </Button>
    );
  }

  return null;
}

export function Home() {
  return (
    <>
      <Container>
        <h1 className="my-4">Strona główna</h1>
        <Link to={`/map`}>
          <Button variant="outline-primary" className="mb-4">
            Mapa lokalizacji pojazdów
          </Button>
        </Link>
        <br />
        <Link to={`/stops`}>
          <Button variant="outline-primary" className="mb-4">
            Odjazdy z przystanków
          </Button>
        </Link>
        <br />
        <Link to={`/blocks`}>
          <Button variant="outline-primary" className="mb-4">
            Brygady
          </Button>
        </Link>
        <br />
        <Link to={`/trips/hidden`}>
          <Button variant="outline-primary" className="mb-4">
            Kursy zjazdowe i wyjazdowe
          </Button>
        </Link>
        <br />
        <NearStops />
      </Container>
    </>
  );
}
