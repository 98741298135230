import { useReadLocalStorage } from "usehooks-ts";

export function useFavoriteStops() {
  const stopNames = useReadLocalStorage<string[]>("favorite_stops");
  if (stopNames === null) {
    return [];
  }
  stopNames.sort((a, b) => a.localeCompare(b));
  return stopNames;
}
