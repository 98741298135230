import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export interface PlannedPassage {
  block_id: string;
  trip_id: string;
  service_id: string;
  departure_time: string;
  route_short_name: string;
  trip_headsign: string;
  arrival: boolean;
  hidden: boolean;
  category: string;
}

export interface Weekdays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface Service extends Weekdays {
  service_id: string;
}

export function usePlannedStopPassages(stopName: string) {
  const { data, error } = useFetch<{
    passages: PlannedPassage[];
    services: Service[];
  }>(`${BACKEND_BASE_URL}/api/stops/${stopName}/passages`);

  const [passages, setPassages] = useState<PlannedPassage[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setPassages(data.passages);
      setServices(data.services);
      setLoading(false);
    }
  }, [data]);

  return { passages, services, loading, error };
}
