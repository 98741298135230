import "./StarToggle.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useCallback, useMemo } from "react";

const EMPTY_STAR = "☆";
const FULL_STAR = "★";

interface StarToggleProps {
  kmkId: string;
}

export function StarToggle({ kmkId }: StarToggleProps) {
  const { isFavorite, toggleFavorite } = useFavoriteVehicles();

  const isVehicleFavorite = useMemo(
    () => isFavorite(kmkId),
    [isFavorite, kmkId]
  );

  const handleClick = useCallback(() => {
    toggleFavorite(kmkId);
  }, [toggleFavorite, kmkId]);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {isVehicleFavorite ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
        </Tooltip>
      }
    >
      <div
        className={`star-toggle star-toggle-${
          isVehicleFavorite ? "full" : "empty"
        }`}
        onClick={handleClick}
      >
        {isVehicleFavorite ? FULL_STAR : EMPTY_STAR}
      </div>
    </OverlayTrigger>
  );
}
