import { isCemeteryRoute, isNightRoute } from "../utils";
import "./RouteShortNameBox.css";

import { Link } from "react-router-dom";

interface RouteShortNameBoxProps {
  routeShortName: string;
  link?: boolean;
  large?: boolean;
}

export function RouteShortNameBox({
  routeShortName,
  link = true,
  large = false,
}: RouteShortNameBoxProps) {
  const classNames = ["box", routeShortName.length <= 2 ? "tram" : "bus"];

  if (large) {
    classNames.push("large");
  }

  if (isNightRoute(routeShortName)) {
    classNames.push("night");
  } else if (isCemeteryRoute(routeShortName)) {
    classNames.push("cemetery");
  }

  const box = <div className={classNames.join(" ")}>{routeShortName}</div>;

  return link ? <Link to={`/routes/${routeShortName}`}>{box}</Link> : box;
}
