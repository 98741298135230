import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { useCurrentStopTimes } from "../hooks/useCurrentStopTimes";
import { formatTime } from "../utils";
import { Arrival } from "./Arrival";
import { Countdown } from "./Countdown";
import { Delay } from "./Delay";
import { Error } from "./Error";
import { KmkId } from "./KmkId";
import { LiveIcon } from "./LiveIcon";
import { ReloadLink } from "./ReloadLink";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import { StopLink } from "./StopLink";
import { Strikethrough } from "./Strikethrough";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";

interface CurrentStopTimesProps {
  stopName: string;
}

export function CurrentStopTimes({ stopName }: CurrentStopTimesProps) {
  const { currentStopTimes, loading, error } = useCurrentStopTimes(
    stopName,
    10_000
  );

  const { isFavorite } = useFavoriteVehicles();

  if (error?.message === "NOT FOUND") {
    return (
      <Container className="g-sm-0">
        <Alert variant="secondary">
          Nie znaleziono przystanku. <ReloadLink />
        </Alert>
      </Container>
    );
  }

  if (error && error?.message) {
    return (
      <Container className="g-sm-0">
        <Error error={error} />
      </Container>
    );
  }

  return (
    <Table
      size="sm"
      bordered
      hover
      className="table-td-align-middle table-layout-fixed text-nowrap"
    >
      <thead>
        <tr>
          <th style={{ width: "20%" }} className="text-center">
            Czas
            <span className="d-none d-xl-inline"> do odjazdu</span>
          </th>
          <th style={{ width: "10%" }} className="text-center">
            Linia
          </th>
          <th>Kierunek</th>
          <th style={{ width: "15%" }} className="text-center">
            Odjazd
          </th>
          <th style={{ width: "15%" }} className="text-center">
            Pojazd
          </th>
        </tr>
      </thead>
      <tbody>
        {currentStopTimes.map((currentStopTime) => (
          <tr
            key={`${currentStopTime.category}_${currentStopTime.service_id}_${currentStopTime.block_id}_${currentStopTime.trip_id}_${currentStopTime.planned_departure_time}`}
            className={
              currentStopTime.old
                ? "bg-dark bg-opacity-10 opacity-75"
                : undefined
            }
          >
            {/* TODO: highlight if departing */}
            <td className="text-center">
              {currentStopTime.old ? (
                "✓"
              ) : (
                <Countdown
                  timestamp={
                    currentStopTime.live
                      ? currentStopTime.predicted_departure_timestamp!
                      : currentStopTime.planned_departure_timestamp
                  }
                />
              )}
              {currentStopTime.live && <LiveIcon />}
            </td>
            <td className="text-center">
              {currentStopTime.route_short_name ? (
                <div
                  style={currentStopTime.hidden ? { opacity: 0.2 } : undefined}
                >
                  <RouteShortNameBox
                    routeShortName={currentStopTime.route_short_name}
                  />
                </div>
              ) : (
                <RouteShortNameBoxPlaceholder />
              )}
            </td>
            <td className="text-truncate">
              <Strikethrough enabled={currentStopTime.old}>
                <StopLink
                  stopName={currentStopTime.trip_headsign}
                  removeNz
                  expandDepotName
                />
              </Strikethrough>
            </td>
            <td className="text-center">
              <Link
                to={`/blocks/${currentStopTime.category}/${currentStopTime.service_id}/${currentStopTime.block_id}/trips/${currentStopTime.trip_id}`}
                className="hidden-link"
              >
                <Strikethrough enabled={currentStopTime.old}>
                  {formatTime(currentStopTime.planned_departure_time)}
                  {currentStopTime.arrival && <Arrival />}
                </Strikethrough>
              </Link>
              <Delay seconds delay={currentStopTime.predicted_delay} />
            </td>
            <td className="text-center">
              {currentStopTime.kmk_id !== null && (
                <div
                  className={
                    isFavorite(currentStopTime.kmk_id) && !currentStopTime.old
                      ? "d-inline-block px-1 bg-warning bg-opacity-50"
                      : undefined
                  }
                >
                  <KmkId kmk_id={currentStopTime.kmk_id} />
                </div>
              )}
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={5} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && currentStopTimes.length === 0 && (
          <tr>
            <td colSpan={5} className="py-3 text-center text-secondary">
              Brak kursów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
