import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export type Category = "tram" | "bus";

export interface Trip {
  trip_id: string;
  trip_num: number;
  route_short_name: string | null;
  stop_name: string | null;
  trip_headsign: string;
  departure_time: string | null;
  arrival_time: string | null;
  hidden: boolean | null;
  kmk_id: string | null;
  timestamp: number | null;
}

export function useBlock(
  category: Category,
  serviceId: string,
  blockId: string
) {
  const { data, error } = useFetch<{ trips: Trip[] }>(
    `${BACKEND_BASE_URL}/api/blocks/${category}/${serviceId}/${blockId}/trips`
  );

  const [trips, setTrips] = useState<Trip[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setTrips(data.trips);
      setLoading(false);
    }
  }, [data]);

  return { trips, loading, error };
}
