import { RouteShortNameBox } from "./RouteShortNameBox";

interface RouteGroupProps {
  groupName: string;
  routeShortNames: string[];
}

export function RouteGroup({ groupName, routeShortNames }: RouteGroupProps) {
  return (
    <div className="mb-3">
      <h2 className="h6">{groupName}</h2>
      {routeShortNames.map((routeShortName) => (
        <div
          style={{ marginRight: 6, marginBottom: 6, display: "inline-block" }}
          key={routeShortName}
        >
          <RouteShortNameBox routeShortName={routeShortName} large />
        </div>
      ))}
    </div>
  );
}
