import "./StarIcon.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const FULL_STAR = "★";

export function StarIcon() {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Ulubiony</Tooltip>}>
      <div className="star">{FULL_STAR}</div>
    </OverlayTrigger>
  );
}
