import {
  PlannedPassage,
  Service,
  usePlannedStopPassages,
} from "../hooks/usePlannedStopPassages";

import { Arrival } from "../components/Arrival";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { RouteShortNameBox } from "../components/RouteShortNameBox";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "../components/StopLink";
import Table from "react-bootstrap/Table";
import { formatTime } from "../utils";
import { useLocalStorage } from "usehooks-ts";
import { useState } from "react";

interface PlannedStopPassagesInnerProps {
  passages: PlannedPassage[];
  services: Service[];
  onlyHidden: boolean;
}

function PlannedStopPassagesInner({
  passages,
  services,
  onlyHidden,
}: PlannedStopPassagesInnerProps) {
  const [serviceId, setServiceId] = useState(services[0]?.service_id);

  const [showArrivals, setShowArrivals] = useLocalStorage<boolean>(
    "show_arrivals",
    false
  );

  const filteredPassages = passages.filter(
    (passage) =>
      passage.service_id === serviceId &&
      (!onlyHidden || passage.hidden) &&
      (showArrivals || !passage.arrival)
  );

  return (
    <>
      <div className="mb-3">
        <ServiceSelect
          services={services}
          serviceId={serviceId}
          setServiceId={setServiceId}
          disabledFunc={(serviceId) =>
            !passages.some((passage) => passage.service_id === serviceId)
          }
        />
      </div>
      <Form.Group className="mb-3" controlId="checked">
        <Form.Check
          type="checkbox"
          label="Pokaż przyjazdy"
          checked={showArrivals}
          onChange={(e) => setShowArrivals(e.target.checked)}
        />
      </Form.Group>
      <Table size="sm" bordered hover className="table-td-align-middle">
        <thead>
          <tr>
            <th style={{ width: "15%" }} className="text-center">
              Odjazd
            </th>
            <th style={{ width: "12%" }} className="text-center">
              Linia
            </th>
            <th>Kierunek</th>
            <th style={{ width: "20%" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredPassages.map((passage) => (
            <tr
              key={`${passage.block_id}_${passage.trip_id}_${passage.service_id}_${passage.departure_time}`}
            >
              <td className="text-center">
                {formatTime(passage.departure_time)}
                {passage.arrival && <Arrival />}
              </td>
              <td className="text-center">
                <div style={passage.hidden ? { opacity: 0.2 } : undefined}>
                  <RouteShortNameBox
                    routeShortName={passage.route_short_name}
                  />
                </div>
              </td>
              <td>
                <StopLink
                  stopName={passage.trip_headsign}
                  removeNz
                  expandDepotName
                />
              </td>
              <td className="text-center">
                <Link
                  to={`/blocks/${passage.category}/${passage.service_id}/${passage.block_id}/trips/${passage.trip_id}`}
                >
                  Rozkład kursu
                </Link>
              </td>
            </tr>
          ))}
          {filteredPassages.length === 0 && (
            <tr>
              <td colSpan={4} className="py-3 text-center text-secondary">
                Brak odjazdów.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

interface PlannedStopPassagesProps {
  stopName: string;
  onlyHidden?: boolean;
}

export function PlannedStopPassages({
  stopName,
  onlyHidden,
}: PlannedStopPassagesProps) {
  const { passages, services, loading, error } =
    usePlannedStopPassages(stopName);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <PlannedStopPassagesInner
      passages={passages}
      services={services}
      onlyHidden={onlyHidden ?? false}
    />
  );
}
