import "./Countdown.css";

import { useInterval } from "usehooks-ts";
import { useState } from "react";

interface CountdownProps {
  timestamp: number;
}

export function Countdown({ timestamp }: CountdownProps) {
  const [now, setNow] = useState(Date.now());

  useInterval(() => setNow(Date.now()), 1000);

  const seconds = Math.round(timestamp - now / 1000);

  if (seconds <= -90) {
    return null;
  }

  if (seconds <= 0) {
    // TODO: synchronize blinking across rows
    return <span className="blink">{">>>"}</span>;
  }

  if (seconds < 60) {
    return (
      <span className="nobr">
        {seconds} <small>s</small>
      </span>
    );
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return (
      <span className="nobr">
        {minutes} <small>min</small>
      </span>
    );
  }

  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(Math.floor(seconds / 60) / 60);
  return (
    <span className="nobr">
      {hours} <small>h</small> {minutes} <small>min</small>
    </span>
  );
}
