import { Source, useVehiclesByRoute } from "../hooks/useVehiclesByRoute";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Counter } from "../components/Counter";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import FormSelect from "react-bootstrap/FormSelect";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { VehiclesByRouteTable } from "../components/VehiclesByRouteTable";
import { getFullSourceName } from "../utils";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useLocalStorage } from "usehooks-ts";
import { useMemo } from "react";

export function VehiclesByRoute() {
  const [source, setSource] = useLocalStorage<Source>(
    "vehicles_by_route_source",
    "gtfs"
  );

  const handleSourceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSource(e.target.value as Source);
  };

  const { vehicles, timestamp, loading, error } = useVehiclesByRoute(
    source,
    10_000
  );

  const [showOnlyFavorite, setShowOnlyFavorite] = useLocalStorage<boolean>(
    "show_only_favorite",
    false
  );

  const [showOnlyLive, setShowOnlyLive] = useLocalStorage<boolean>(
    "show_only_live",
    true
  );

  const [showFloor, setShowFloor] = useLocalStorage<boolean>(
    "show_floor",
    true
  );

  const { isFavorite } = useFavoriteVehicles();

  const filteredVehicles = useMemo(() => {
    const now = Date.now();
    let result = vehicles.map((vehicle) => ({
      ...vehicle,
      is_live: now / 1000 - vehicle.timestamp <= 7 * 60, // 7 min
    }));
    if (showOnlyFavorite) {
      result = result.filter((vehicle) => isFavorite(vehicle.kmk_id));
    }
    if (showOnlyLive) {
      result = result.filter((vehicle) => vehicle.is_live);
    }
    return result;
  }, [vehicles, isFavorite, showOnlyFavorite, showOnlyLive]);

  return (
    <Container>
      <PageTitle title="Pojazdy na liniach" />
      <h1 className="my-4">Pojazdy na liniach</h1>
      <Form.Group className="mb-1" controlId="show_only_favorite">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko ulubione"
          checked={showOnlyFavorite}
          onChange={(e) => setShowOnlyFavorite(e.target.checked)}
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="show_outdated">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko aktualne"
          checked={showOnlyLive}
          onChange={(e) => setShowOnlyLive(e.target.checked)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="show_floor">
        <Form.Check
          type="checkbox"
          label="Koloruj według podłogi"
          checked={showFloor}
          onChange={(e) => setShowFloor(e.target.checked)}
        />
      </Form.Group>
      <FormSelect
        value={source}
        className="w-auto mb-3"
        onChange={handleSourceChange}
      >
        {["gtfs", "kokon"].map((source) => (
          <option value={source} key={source}>
            {getFullSourceName(source)}
          </option>
        ))}
      </FormSelect>
      {!loading && timestamp && (
        <p>
          Ostatnia aktualizacja: <Counter timestamp={timestamp / 1000} />
        </p>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : filteredVehicles.length === 0 ? (
        <Alert variant="secondary">
          Brak {showOnlyFavorite && "ulubionych"} pojazdów.
        </Alert>
      ) : (
        <div className="mb-4">
          <VehiclesByRouteTable
            vehicles={filteredVehicles}
            showFloor={showFloor}
          />
        </div>
      )}
    </Container>
  );
}
