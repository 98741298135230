import { useLocalStorage } from "usehooks-ts";

export function useFavoriteStop(stopName: string) {
  const [value, setValue] = useLocalStorage<string[]>("favorite_stops", []);

  const isFavorite = value.includes(stopName);

  const setFavorite = (isFavorite: boolean) => {
    const favorite = value.filter((item) => item !== stopName);
    if (isFavorite) {
      favorite.push(stopName);
    }
    setValue(favorite);
  };

  return { isFavorite, setFavorite };
}
