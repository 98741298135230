import { ReactNode, useLayoutEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useMap } from "react-map-gl/maplibre";

export function useMapImage(id: string, element: ReactNode) {
  const mapRef = useMap();

  useLayoutEffect(() => {
    const map = mapRef.current;
    if (map) {
      const img = new Image();
      const markup = renderToStaticMarkup(<>{element}</>);
      img.src = `data:image/svg+xml;base64,${btoa(markup)}`;
      let loaded = false;
      img.onload = () => {
        if (!map.hasImage(id)) {
          map.addImage(id, img);
          loaded = true;
        }
      };
      return () => {
        if (loaded) {
          map.removeImage(id);
        }
      };
    }
  }, [mapRef, id, element]);
}
