import Container from "react-bootstrap/Container";

function reload() {
  window.location.reload();
}

const FAVORITE_STOPS_LOCAL_STORAGE_KEY = "favorite_stops";
const FAVORITE_VEHICLES_LOCAL_STORAGE_KEY = "favorite_vehicles_v2";

function clearLocalStorage() {
  const stops = localStorage.getItem(FAVORITE_STOPS_LOCAL_STORAGE_KEY);
  const vehicles = localStorage.getItem(FAVORITE_VEHICLES_LOCAL_STORAGE_KEY);
  localStorage.clear();
  if (stops) {
    localStorage.setItem(FAVORITE_STOPS_LOCAL_STORAGE_KEY, stops);
  }
  if (vehicles) {
    localStorage.setItem(FAVORITE_VEHICLES_LOCAL_STORAGE_KEY, vehicles);
  }
}

export function Fallback() {
  return (
    <Container className="my-5">
      <h2 className="mb-3">Wystąpił błąd</h2>
      <button className="btn btn-outline-primary me-2" onClick={reload}>
        Odśwież stronę
      </button>
      <button className="btn btn-outline-primary" onClick={clearLocalStorage}>
        Wyczyść lokalną pamięć
      </button>
    </Container>
  );
}
