import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { Vehicle, useVehicles } from "../hooks/useVehicles";
import Table from "react-bootstrap/Table";
import { KmkId } from "../components/KmkId";
import Form from "react-bootstrap/Form";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { compareKmkId } from "../utils";
import { Counter } from "../components/Counter";
import { StarToggle } from "../components/StarToggle";
import { useInterval } from "usehooks-ts";
import { LiveIcon } from "../components/LiveIcon";

function searchVehiclesByKmkId(vehicles: Vehicle[], query: string) {
  const pattern = query.toLocaleUpperCase();
  return vehicles.filter((vehicle) => vehicle.full_kmk_id.includes(pattern));
}

function sortVehiclesByKmkId(vehicles: Vehicle[]) {
  const copy = vehicles.slice();
  copy.sort((a: Vehicle, b: Vehicle) => compareKmkId(a.kmk_id, b.kmk_id));
  return copy;
}

function isDateToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function getLatestActivityText(timestamp: number, seconds: number) {
  if (seconds <= 0) {
    return "teraz";
  }

  if (seconds < 60) {
    return `${seconds} s temu`;
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} min ${seconds % 60} s temu`;
  }

  const date = new Date(timestamp * 1000);
  const hourString = date.toLocaleTimeString().substring(0, 5);

  if (isDateToday(date)) {
    return `dzisiaj, ${hourString}`;
  }

  const dateString = date.toLocaleDateString();
  return `${dateString} ${hourString}`;
}

interface LatestActivityProps {
  timestamp: number;
  routeShortName: string | null;
  tripHeadsign: string | null;
}

function LatestActivity({
  timestamp,
  routeShortName,
  tripHeadsign,
}: LatestActivityProps) {
  const [now, setNow] = useState(Date.now());

  useInterval(() => setNow(Date.now()), 1000);

  const seconds = Math.round(now / 1000 - timestamp);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {routeShortName} &rarr; {tripHeadsign}
          </Tooltip>
        }
      >
        <span className={seconds >= 3600 ? "opacity-25 nobr" : "nobr"}>
          {getLatestActivityText(timestamp, seconds)}
        </span>
      </OverlayTrigger>
      {seconds < 2 * 60 && <LiveIcon />}
    </>
  );
}

interface VehiclesListProps {
  vehicles: Vehicle[];
}

function VehiclesList({ vehicles }: VehiclesListProps) {
  return (
    <Table
      size="sm"
      striped
      bordered
      hover
      className="table-td-align-middle table-layout-fixed text-nowrap"
    >
      <thead>
        <tr>
          <th className="text-center" style={{ width: "20%" }}>
            Numer taborowy
          </th>
          <th className="text-center" style={{ width: "10%" }}>
            Model
          </th>
          <th className="text-center" style={{ width: "15%" }}>
            TTSS
          </th>
          <th className="text-center" style={{ width: "15%" }}>
            GTFS Realtime
          </th>
          <th className="text-center" style={{ width: "15%" }}>
            Kokon
          </th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle) => (
          <tr key={vehicle.kmk_id}>
            <td className="text-center">
              <KmkId kmk_id={vehicle.full_kmk_id} />
              <StarToggle kmkId={vehicle.kmk_id} />
            </td>
            <td className="text-center">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{vehicle.full_model_name}</Tooltip>}
              >
                <small>{vehicle.short_model_name}</small>
              </OverlayTrigger>
            </td>
            <td className="text-center">
              {vehicle.ttss_timestamp && (
                <LatestActivity
                  timestamp={vehicle.ttss_timestamp}
                  routeShortName={vehicle.ttss_route_short_name}
                  tripHeadsign={vehicle.ttss_trip_headsign}
                />
              )}
            </td>
            <td className="text-center">
              {vehicle.gtfs_timestamp && (
                <LatestActivity
                  timestamp={vehicle.gtfs_timestamp}
                  routeShortName={vehicle.gtfs_route_short_name}
                  tripHeadsign={vehicle.gtfs_trip_headsign}
                />
              )}
            </td>
            <td className="text-center">
              {vehicle.kokon_timestamp && (
                <LatestActivity
                  timestamp={vehicle.kokon_timestamp}
                  routeShortName={vehicle.kokon_route_short_name}
                  tripHeadsign={vehicle.kokon_trip_headsign}
                />
              )}
            </td>
          </tr>
        ))}
        {vehicles.length === 0 && (
          <tr>
            <td colSpan={5} className="py-3 text-center text-secondary">
              Brak pojazdów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export function Vehicles() {
  const { timestamp, vehicles, loading, error } = useVehicles();

  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const [showOnlyFavorite, setShowOnlyFavorite] = useState(false);

  const { isFavorite } = useFavoriteVehicles();

  const filteredVehicles = useMemo(() => {
    let results = vehicles;
    if (showOnlyFavorite && query.length === 0) {
      results = results.filter(
        (v) => v.kmk_id !== null && isFavorite(v.kmk_id)
      );
    }
    results = searchVehiclesByKmkId(results, query);
    results = sortVehiclesByKmkId(results);
    return results;
  }, [vehicles, query, showOnlyFavorite, isFavorite]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && filteredVehicles.length === 1) {
        navigate(`/vehicles/${filteredVehicles[0].full_kmk_id}`);
      }
    },
    [filteredVehicles, navigate]
  );

  return (
    <Container>
      <PageTitle title="Baza pojazdów" />
      <h1 className="my-4">Baza pojazdów</h1>
      <Form.Group className="mb-3" controlId="checked">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko ulubione pojazdy"
          checked={showOnlyFavorite}
          onChange={(e) => setShowOnlyFavorite(e.target.checked)}
        />
      </Form.Group>
      <div className="mb-3 col-lg-3">
        <Form.Control
          type="text"
          placeholder="Wpisz numer taborowy..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      {timestamp && (
        <p className="mb-3">
          Ostatnia aktualizacja: <Counter timestamp={timestamp / 1000} />
        </p>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <VehiclesList vehicles={filteredVehicles} />
      )}
    </Container>
  );
}
