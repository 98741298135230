import Container from "react-bootstrap/Container";
import { Counter } from "../components/Counter";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import PageTitle from "../components/PageTitle";
import { VehiclesList } from "../components/VehiclesList";
import { useActiveVehicles } from "../hooks/useActiveVehicles";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useLocalStorage } from "usehooks-ts";

export function ActiveVehicles() {
  const { vehicles, timestamp, loading, error } = useActiveVehicles(
    "gtfs",
    10_000
  );

  const [checked, setChecked] = useLocalStorage<boolean>(
    "show_only_favorite",
    false
  );

  const { isFavorite } = useFavoriteVehicles();

  const filteredVehicles = checked
    ? vehicles.filter((v) => v.kmk_id !== null && isFavorite(v.kmk_id))
    : vehicles;

  return (
    <Container>
      <PageTitle title="Kursy" />
      <h1 className="my-4">Kursy</h1>
      <Form.Group className="mb-3" controlId="checked">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko ulubione pojazdy"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </Form.Group>
      {!loading && timestamp && (
        <p>
          Ostatnia aktualizacja: <Counter timestamp={timestamp / 1000} />
        </p>
      )}
      {error ? (
        <Error error={error} />
      ) : (
        <VehiclesList vehicles={filteredVehicles} loading={loading} />
      )}
    </Container>
  );
}
