import { Group } from "../hooks/useRoutes";
import { RouteGroup } from "./RouteGroup";

interface RouteGroupsProps {
  groups: Group[];
}

export function RouteGroups({ groups }: RouteGroupsProps) {
  return (
    <>
      {groups.map((group) => (
        <RouteGroup
          groupName={group.group_name}
          routeShortNames={group.route_short_names}
          key={group.group_name}
        />
      ))}
    </>
  );
}
