import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";

export interface Group {
  group_name: string;
  route_short_names: string[];
}

function useFetchRoutes() {
  const { data, error } = useFetch<{ groups: Group[] }>(
    `${BACKEND_BASE_URL}/api/routes`
  );

  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setGroups(data.groups);
      setLoading(false);
    }
  }, [data]);

  return { groups, loading, error };
}

export function useRoutes() {
  const { groups, loading, error } = useFetchRoutes();

  const [localStorage, setLocalStorage] = useLocalStorage<Group[] | null>(
    "routes",
    null
  );

  // update local storage after fetch
  useEffect(() => {
    if (!loading) {
      setLocalStorage(groups);
    }
  }, [loading, setLocalStorage, groups]);

  if (loading && localStorage !== null) {
    return { groups: localStorage, loading: false, error: undefined };
  }

  return { groups, loading, error };
}
