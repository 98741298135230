import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

function useFetchStopCentroids() {
  const { data, error } = useFetch<unknown>(
    "https://services-eu1.arcgis.com/svTzSt3AvH7sK6q9/arcgis/rest/services/przystanki_kmk_centroid/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson"
  );

  const [stopCentroids, setStopCentroids] = useState<unknown>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStopCentroids(data);
      setLoading(false);
    }
  }, [data]);

  return { stopCentroids, loading, error };
}

export function useStopCentroids() {
  const { stopCentroids, loading, error } = useFetchStopCentroids();

  const [localStorage, setLocalStorage] = useLocalStorage<any>(
    "stop_centroids",
    null
  );

  useEffect(() => {
    if (!loading) {
      setLocalStorage(stopCentroids);
    }
  }, [loading, setLocalStorage, stopCentroids]);

  if (loading && localStorage !== null) {
    return { stopCentroids: localStorage, loading: false };
  }

  return { stopCentroids, loading, error };
}
