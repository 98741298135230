import Alert from "react-bootstrap/Alert";
import { ReloadLink } from "./ReloadLink";

interface ErrorProps {
  error: Error;
}

export function Error({ error }: ErrorProps) {
  if (error.message === "SERVICE UNAVAILABLE") {
    return (
      <Alert variant="info">
        Trwa pobieranie danych. <ReloadLink />
      </Alert>
    );
  }

  return (
    <Alert variant="danger">
      Wystąpił błąd: <code>{error.message}</code> <ReloadLink />
    </Alert>
  );
}
