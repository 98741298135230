import "./KmkId.css";

import { Fragment } from "react";
import { Link } from "react-router-dom";

interface KmkIdProps {
  kmk_id: string;
  link?: boolean;
}

export function KmkId({ kmk_id, link = true }: KmkIdProps) {
  const inner = kmk_id.split("+").map((part, i) => (
    <Fragment key={i}>
      {i !== 0 && "+"}
      <small className="prefix">{part.substring(0, 2)}</small>
      {part.substring(2, 5)}
    </Fragment>
  ));

  return link ? (
    <Link to={`/vehicles/${kmk_id}`} className="hidden-link kmk-id">
      {inner}
    </Link>
  ) : (
    <>{inner}</>
  );
}
