import { Status as StatusType, useStatus } from "../hooks/useStatus";

import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";

const services = [
  "backend",
  "GTFS_KRK_A.zip",
  "GTFS_KRK_T.zip",
  "GTFS_KRK_M.zip",
  "VehiclePositions_A.pb",
  "VehiclePositions_T.pb",
  "VehiclePositions_M.pb",
  "TripUpdates_A.pb",
  "TripUpdates_T.pb",
  "TripUpdates_M.pb",
  "ServiceAlerts_A.pb",
  "ServiceAlerts_T.pb",
  "ServiceAlerts_M.pb",
  "ttss.mpk.krakow.pl",
  "www.ttss.krakow.pl",
  "kokon.mpk.krakow.pl",
];

interface ServiceProps {
  service: string;
}

function getEmojiForStatus(status: StatusType) {
  switch (status) {
    case "ok":
      return "✅";
    case "warning":
      return "⚠️";
    case "error":
      return "❌";
  }
}

function Service({ service }: ServiceProps) {
  const { response, loading, error } = useStatus(service);

  const icon = error ? (
    <span>{getEmojiForStatus("error")}</span>
  ) : loading || !response ? (
    <Spinner animation="border" size="sm" variant="primary" role="status" />
  ) : (
    <OverlayTrigger
      placement="top"
      overlay={response.message ? <Tooltip>{response.message}</Tooltip> : <></>}
    >
      <span>{getEmojiForStatus(response.status)}</span>
    </OverlayTrigger>
  );

  return (
    <ListGroupItem>
      {icon}&ensp;{service}
    </ListGroupItem>
  );
}

export function Status() {
  return (
    <Container>
      <PageTitle title="Status" />
      <h1 className="my-4">Status</h1>
      <ListGroup className="mb-4">
        {services.map((service) => (
          <Service service={service} key={service} />
        ))}
      </ListGroup>
    </Container>
  );
}
