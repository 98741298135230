import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

function useFetchTicketZones() {
  const { data, error } = useFetch<unknown>(
    "https://services-eu1.arcgis.com/svTzSt3AvH7sK6q9/arcgis/rest/services/Strefy_taryfowe/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson"
  );

  const [ticketZones, setTicketZones] = useState<unknown>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setTicketZones(data);
      setLoading(false);
    }
  }, [data]);

  return { ticketZones, loading, error };
}

export function useTicketZones() {
  const { ticketZones, loading, error } = useFetchTicketZones();

  const [localStorage, setLocalStorage] = useLocalStorage<any>(
    "ticket_zones",
    null
  );

  useEffect(() => {
    if (!loading) {
      setLocalStorage(ticketZones);
    }
  }, [loading, setLocalStorage, ticketZones]);

  if (loading && localStorage !== null) {
    return { ticketZones: localStorage, loading: false };
  }

  return { ticketZones, loading, error };
}
