import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

const DEFAULT_FAVORITE_VEHICLES = [
  "HW101",
  "RP650",
  "RY899",
  "RG914",
  "HG999",
  "DR743",
];

export function useFavoriteVehicles() {
  const [favoriteVehicles, setFavoriteVehicles] = useLocalStorage(
    "favorite_vehicles_v2",
    DEFAULT_FAVORITE_VEHICLES
  );

  const isFavorite = useCallback(
    (kmkId: string) => {
      return favoriteVehicles.includes(kmkId);
    },
    [favoriteVehicles]
  );

  const toggleFavorite = useCallback(
    (kmkId: string) => {
      if (isFavorite(kmkId)) {
        setFavoriteVehicles((prev) => prev.filter((id) => id !== kmkId));
      } else {
        setFavoriteVehicles((prev) => [...prev, kmkId]);
      }
    },
    [isFavorite, setFavoriteVehicles]
  );

  return { isFavorite, toggleFavorite };
}
