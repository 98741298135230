import { Weekdays } from "./hooks/useRoute";

export function containsOnlyDigits(str: string) {
  return /^\d*$/.test(str);
}

export function compareRouteShortName(a: string, b: string) {
  if (containsOnlyDigits(a) && containsOnlyDigits(b)) {
    return parseInt(a) - parseInt(b);
  } else if (containsOnlyDigits(a)) {
    return -1;
  } else if (containsOnlyDigits(b)) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
}

export function compareKmkNumber(a: string, b: string) {
  return a.substring(2, 5).localeCompare(b.substring(2, 5));
}

export function compareNull<T>(first: T, second: T) {
  if (first === null && second === null) {
    return 0;
  }
  if (first === null) {
    return -1;
  }
  if (second === null) {
    return 1;
  }
  return 0;
}

function score(x: string) {
  if (x.startsWith("R") || x.startsWith("H")) {
    return 1;
  }
  if (x.startsWith("M")) {
    return -1;
  }
  return 0;
}

export function compareCategory(first: string, second: string) {
  return Math.sign(score(second) - score(first));
}

export function compareKmkId(first: string | null, second: string | null) {
  return (
    compareNull(first, second) ||
    compareCategory(first!, second!) ||
    compareKmkNumber(first!, second!)
  );
}

type Mode =
  | "current_stop_times"
  | "departures"
  | "arrivals"
  | "planned"
  | "hidden";

export function getStopUrl(
  stopName: string,
  mode: Mode = "current_stop_times"
) {
  return `/stops/${encodeStopName(stopName)}/${mode}`;
}

export function encodeStopName(stopName: string) {
  return stopName.replaceAll(" / ", "___").replaceAll(" ", "_");
}

export function decodeStopName(stopName: string) {
  return stopName.replaceAll("___", " / ").replaceAll("_", " ");
}

export function expandDepotName(stopName: string) {
  if (stopName === "PT") {
    return "Zajezdnia Podgórze";
  }
  if (stopName === "PH") {
    return "Zajezdnia Nowa Huta";
  }
  return stopName;
}

export function isInteger(value: string) {
  return !Number.isNaN(Number(value)) && value !== "";
}

export function isAgglomerationRoute(routeShortName: string) {
  return (
    routeShortName.length === 3 && ["2", "3", "9"].includes(routeShortName[0])
  );
}

export function isNightRoute(routeShortName: string) {
  return (
    (routeShortName.length >= 2 && ["6", "9"].includes(routeShortName[0])) ||
    routeShortName === "769"
  );
}

export function isCemeteryRoute(routeShortName: string) {
  return routeShortName.length >= 2 && routeShortName[0] === "8";
}

export function isValidCategory(value: string) {
  return value === "tram" || value === "bus" || value === "mobilis";
}

export function isValidKmkId(str: string): boolean {
  return /^[A-Z]{2}[0-9]{3}(\+[A-Z]{2}[0-9]{3}){0,2}$/.test(str);
}

export function formatTime(value: string) {
  const [hour, minute] = value.split(":");
  return `${String(Number.parseInt(hour) % 24).padStart(2, "0")}:${minute}`;
}

export function formatWeekdays(weekdays: Weekdays) {
  const names = [];
  if (weekdays.monday) names.push("poniedziałek");
  if (weekdays.tuesday) names.push("wtorek");
  if (weekdays.wednesday) names.push("środa");
  if (weekdays.thursday) names.push("czwartek");
  if (weekdays.friday) names.push("piątek");
  if (weekdays.saturday) names.push("sobota");
  if (weekdays.sunday) names.push("niedziela");
  return names.join(", ");
}

const INTERNAL_TRIP_HEADSIGNS = [
  "Wyjazd na linię",
  "Zjazd do zajezdni",
  "Przejazd techniczny",
];

export function isTripHeadsignInternal(tripHeadsign: string) {
  return INTERNAL_TRIP_HEADSIGNS.includes(tripHeadsign);
}

export function getFullSourceName(source: string) {
  switch (source) {
    case "ttss":
      return "TTSS";
    case "gtfs":
      return "GTFS";
    case "kokon":
      return "Kokon";
    case "mobilis":
      return "Mobilis";
    default:
      return "?";
  }
}
