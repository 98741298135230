import "./Delay.css";

interface DelayProps {
  delay: number | null;
  seconds?: boolean;
}

export function Delay({ delay, seconds = false }: DelayProps) {
  if (seconds && delay !== null) {
    delay = Math.floor(delay / 60);
  }

  return (
    <div className="delay">
      {delay === null ? null : delay > 0 ? (
        <sup className="bold text-danger">+{delay}</sup>
      ) : delay < 0 ? (
        <sup className="bold text-danger">&ndash;{-delay}</sup>
      ) : delay === 0 ? (
        <sup className="bold text-primary">✓</sup>
      ) : null}
    </div>
  );
}
