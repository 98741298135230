import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export interface History {
  date: string;
  route_short_names: string[];
}

export function useVehicleHistory(kmkId: string) {
  const { data, error } = useFetch<{ history: History[] }>(
    `${BACKEND_BASE_URL}/api/vehicles/${kmkId}/history`
  );

  const [history, setHistory] = useState<History[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      // TODO: sort route short names
      setHistory(data.history);
      setLoading(false);
    }
  }, [data]);

  return { history, loading, error };
}
