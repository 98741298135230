import "./VehicleMap.css";

import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import { useEffect, useMemo } from "react";

import L from "leaflet";
import { Path } from "../hooks/useVehicle";
import ReactDOMServer from "react-dom/server";
import { StopLink } from "./StopLink";

const PATH_COLOR = "#007bff";
const TTSS_COLOR = "orange";
const GTFS_COLOR = "limegreen";
const KOKON_COLOR = "blue";

interface RecenterAutomaticallyProps {
  latitude: number;
  longitude: number;
}

function RecenterAutomatically({
  latitude,
  longitude,
}: RecenterAutomaticallyProps) {
  const map = useMap();

  useEffect(() => {
    map.panTo([latitude, longitude]);
  }, [map, latitude, longitude]);

  return null;
}

interface PathPolylineProps {
  path: Path;
}

function PathPolyline({ path }: PathPolylineProps) {
  const positions = useMemo(() => {
    return path.map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));
  }, [path]);

  return <Polyline positions={positions} color={PATH_COLOR} weight={4} />;
}

interface StopPointMarkerProps {
  latitude: number;
  longitude: number;
  stopName: string;
}

function StopPointMarker({
  latitude,
  longitude,
  stopName,
}: StopPointMarkerProps) {
  const element = (
    <svg width={16} height={16} style={{ zIndex: 100 }}>
      <circle
        cx={8}
        cy={8}
        r={5}
        stroke={PATH_COLOR}
        strokeWidth="3.5"
        fill="rgb(253,253,253)"
      />
    </svg>
  );

  const icon = L.divIcon({
    html: ReactDOMServer.renderToStaticMarkup(element),
    className: undefined,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
  });

  return (
    <Marker position={[latitude, longitude]} icon={icon} zIndexOffset={100}>
      <Popup className="popup">
        <StopLink stopName={stopName} bold />
      </Popup>
    </Marker>
  );
}

interface VehicleMarkerProps {
  latitude: number | null;
  longitude: number | null;
  bearing: number | null;
  color: string;
  zIndex: number;
}

function VehicleMarker({
  latitude,
  longitude,
  bearing,
  color,
  zIndex,
}: VehicleMarkerProps) {
  if (latitude === null || longitude === null) {
    return null;
  }

  const element = (
    <svg
      width={40}
      height={40}
      style={{ rotate: `${bearing ?? 0}deg`, zIndex }}
    >
      <circle
        cx={20}
        cy={20}
        r={10}
        stroke={color}
        strokeWidth="1"
        fill="white"
      />
      <circle cx={20} cy={20} r={7} fill={color} />
      {bearing !== null && <polygon points="20,0 30,7 10,7" fill={color} />}
    </svg>
  );

  const icon = L.divIcon({
    html: ReactDOMServer.renderToStaticMarkup(element),
    className: undefined,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  });

  return (
    <Marker position={[latitude, longitude]} icon={icon} zIndexOffset={200} />
  );
}

interface StopPoint {
  latitude: number;
  longitude: number;
  stop_name: string;
}

interface VehicleMapProps {
  ttssLatitude: number | null;
  ttssLongitude: number | null;
  ttssBearing: number | null;
  ttssTimestamp: number | null;
  gtfsLatitude: number | null;
  gtfsLongitude: number | null;
  gtfsBearing: number | null;
  gtfsTimestamp: number | null;
  kokonLatitude: number | null;
  kokonLongitude: number | null;
  kokonBearing: number | null;
  kokonTimestamp: number | null;
  path?: Path | null;
  stopPoints?: StopPoint[] | null;
}

export function VehicleMap({
  ttssLatitude,
  ttssLongitude,
  ttssBearing,
  ttssTimestamp,
  gtfsLatitude,
  gtfsLongitude,
  gtfsBearing,
  gtfsTimestamp,
  kokonLatitude,
  kokonLongitude,
  kokonBearing,
  kokonTimestamp,
  path,
  stopPoints,
}: VehicleMapProps) {
  const center =
    ttssTimestamp !== null
      ? ([ttssLatitude, ttssLongitude] as [number, number])
      : (gtfsTimestamp ?? 1) >= (kokonTimestamp ?? 0) &&
        gtfsLatitude !== null &&
        gtfsLongitude !== null
      ? ([gtfsLatitude, gtfsLongitude] as [number, number])
      : kokonLatitude !== null && kokonLongitude !== null
      ? ([kokonLatitude, kokonLongitude] as [number, number])
      : undefined;

  return (
    <MapContainer center={center} zoom={15} style={{ aspectRatio: "8 / 7" }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        subdomains="abcd"
      />
      {center !== undefined && (
        <RecenterAutomatically latitude={center[0]} longitude={center[1]} />
      )}
      {path && <PathPolyline path={path} />}
      {stopPoints &&
        stopPoints.map(({ latitude, longitude, stop_name }) => (
          <StopPointMarker
            key={`${latitude},${longitude}`}
            latitude={latitude}
            longitude={longitude}
            stopName={stop_name}
          />
        ))}
      <VehicleMarker
        latitude={kokonLatitude}
        longitude={kokonLongitude}
        bearing={kokonBearing}
        color={KOKON_COLOR}
        zIndex={200}
      />
      <VehicleMarker
        latitude={gtfsLatitude}
        longitude={gtfsLongitude}
        bearing={gtfsBearing}
        color={GTFS_COLOR}
        zIndex={300}
      />
      <VehicleMarker
        latitude={ttssLatitude}
        longitude={ttssLongitude}
        bearing={ttssBearing}
        color={TTSS_COLOR}
        zIndex={400}
      />
    </MapContainer>
  );
}
