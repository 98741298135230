import { useInterval } from "usehooks-ts";
import { useState } from "react";

function getHowLongAgoText(seconds: number) {
  if (seconds <= 0) {
    return "teraz";
  }

  if (seconds < 60) {
    return `${seconds} s temu`;
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} min ${seconds % 60} s temu`;
  }

  if (seconds < 86400) {
    const minutes = Math.floor(seconds / 60) % 60;
    const hours = Math.floor(Math.floor(seconds / 60) / 60);
    return `${hours} h ${minutes} min ${seconds % 60} s temu`;
  }

  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(Math.floor(seconds / 60) / 60) % 24;
  const days = Math.floor(Math.floor(Math.floor(seconds / 60) / 60) / 24);
  return `${days} d ${hours} h ${minutes} min ${seconds % 60} s temu`;
}

interface CounterProps {
  timestamp: number;
}

export function Counter({ timestamp }: CounterProps) {
  const [now, setNow] = useState(Date.now());

  useInterval(() => setNow(Date.now()), 1000);

  const seconds = Math.round(now / 1000 - timestamp);

  return <span className="nobr">{getHowLongAgoText(seconds)}</span>;
}
