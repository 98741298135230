import "./LatencyIcon.css";

import { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useInterval } from "usehooks-ts";
import { Counter } from "./Counter";

interface LatencyIconProps {
  timestamp: number;
}

export function LatencyIcon({ timestamp }: LatencyIconProps) {
  const [now, setNow] = useState(Date.now());

  useInterval(() => setNow(Date.now()), 1000);

  const seconds = Math.round(now / 1000 - timestamp);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <Counter timestamp={timestamp} />
        </Tooltip>
      }
    >
      <div
        className={`latency-icon-${seconds < 7 * 60 ? "live" : "outdated"}`}
      ></div>
    </OverlayTrigger>
  );
}
