import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export type Category = "tram" | "bus" | "mobilis";

export interface Point {
  latitude: number;
  longitude: number;
}

export type Path = Point[];

export function usePath(
  category: Category,
  routeShortName: string,
  tripHeadsign: string
) {
  const params = {
    category: category,
    route_short_name: routeShortName,
    trip_headsign: tripHeadsign,
  };

  const url = `${BACKEND_BASE_URL}/api/paths?` + new URLSearchParams(params);

  const { data, error } = useFetch<{ path: Path }>(url);

  return {
    path: data?.path ?? [],
    loading: data === undefined,
    error,
  };
}
