import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";
import { Floor } from "./useVehicle";
import { useCacheBuster } from "./useCacheBuster";

type Category = "tram" | "bus";

export interface Vehicle {
  kmk_id: string;
  full_kmk_id: string;
  category: Category;
  full_model_name: string;
  short_model_name: string;
  floor: Floor;
  ttss_route_short_name: string | null;
  ttss_trip_headsign: string | null;
  ttss_timestamp: number | null;
  gtfs_route_short_name: string | null;
  gtfs_trip_headsign: string | null;
  gtfs_timestamp: number | null;
  kokon_route_short_name: string | null;
  kokon_trip_headsign: string | null;
  kokon_timestamp: number | null;
}

function useFetchVehicles(interval: number) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{ vehicles: Vehicle[] }>(
    `${BACKEND_BASE_URL}/api/vehicles?t=${cacheBuster}`
  );

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setVehicles(data.vehicles);
      setTimestamp(Date.now());
      setLoading(false);
    }
  }, [data]);

  return { timestamp, vehicles, loading, error };
}

export function useVehicles(interval: number = 10_000) {
  const { timestamp, vehicles, loading, error } = useFetchVehicles(interval);

  const [localStorage, setLocalStorage] = useLocalStorage<{
    vehicles: Vehicle[];
    timestamp: number;
  } | null>("vehicles", null);

  // update local storage after fetch
  useEffect(() => {
    if (!loading && timestamp) {
      setLocalStorage({ vehicles, timestamp });
    }
  }, [timestamp, loading, setLocalStorage, vehicles]);

  if (
    loading &&
    localStorage !== null &&
    Date.now() - localStorage.timestamp < interval
  ) {
    return { ...localStorage, loading: false, error: undefined };
  }

  return { vehicles, timestamp, loading, error };
}
