import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export interface Coords {
  latitude: number;
  longitude: number;
}

export interface NearStop {
  stop_name: string;
  distance_km: number;
}

export function useNearStops(coords: Coords) {
  const { data, error } = useFetch<{ stops: NearStop[] }>(
    `${BACKEND_BASE_URL}/api/stops/near?latitude=${coords.latitude}&longitude=${coords.longitude}`
  );

  const [stops, setStops] = useState<NearStop[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStops(data.stops);
      setLoading(false);
    }
  }, [data]);

  return { stops, loading, error };
}
