import { useCallback, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

export function useFilteredRouteShortNames() {
  const [filteredRouteShortNames, setFilteredRouteShortNames] = useLocalStorage<
    string[]
  >("filtered_route_short_names", []);

  const hasFilter = filteredRouteShortNames.length > 0;

  const shouldShowRoute = useMemo(() => {
    if (hasFilter) {
      const set = new Set(filteredRouteShortNames);
      return (routeShortName: string) => set.has(routeShortName);
    } else {
      return (_routeShortName: string) => true;
    }
  }, [hasFilter, filteredRouteShortNames]);

  const toggleFilter = useCallback(
    (routeShortName: string) => {
      setFilteredRouteShortNames((prev) =>
        prev.includes(routeShortName)
          ? prev.filter((name) => name !== routeShortName)
          : [...prev, routeShortName]
      );
    },
    [setFilteredRouteShortNames]
  );

  const clearFilter = useCallback(() => {
    setFilteredRouteShortNames([]);
  }, [setFilteredRouteShortNames]);

  return {
    hasFilter,
    filteredRouteShortNames,
    shouldShowRoute,
    toggleFilter,
    clearFilter,
  };
}
