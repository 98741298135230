import {
  expandDepotName as expandDepotNameFunction,
  getStopUrl,
  isTripHeadsignInternal,
} from "../utils";

import { Link } from "react-router-dom";

interface StopLinkProps {
  stopName: string;
  bold?: boolean;
  link?: boolean;
  removeNz?: boolean;
  expandDepotName?: boolean;
}

export function StopLink({
  stopName,
  bold = false,
  link = false,
  removeNz = false,
  expandDepotName = false,
}: StopLinkProps) {
  if (isTripHeadsignInternal(stopName)) {
    return <span className={bold ? "fw-bold" : undefined}>{stopName}</span>;
  }

  let displayStopName = stopName;

  if (removeNz) {
    displayStopName = displayStopName.replace(" (nż)", "");
  }

  if (expandDepotName) {
    displayStopName = expandDepotNameFunction(displayStopName);
  }

  return (
    <Link
      to={getStopUrl(stopName)}
      className={(link ? "" : "hidden-link ") + (bold ? "fw-bold " : "")}
    >
      {displayStopName}
    </Link>
  );
}
