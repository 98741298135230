import "./Timetable.css";

import React, { useEffect, useMemo, useRef } from "react";

import { Arrival } from "./Arrival";
import { Countdown } from "./Countdown";
import { Departure } from "../hooks/useDepartures";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { isAgglomerationRoute } from "../utils";

function getHour(departure: Departure) {
  return Number.parseInt(departure.departure_time.substring(0, 2));
}

function getMinute(departure: Departure) {
  return departure.departure_time.substring(3, 5);
}

function range(min: number, max: number) {
  return Array.from({ length: max - min + 1 }, (_, i) => min + i);
}

interface TimetableProps {
  routeShortName: string;
  stopPointName: string;
  departures: Departure[];
  serviceId: string;
}

export function Timetable({
  routeShortName,
  stopPointName,
  departures,
  serviceId,
}: TimetableProps) {
  const [minHour, maxHour] = useMemo(() => {
    const hours = departures.map(getHour);
    return [Math.min(...hours), Math.max(...hours)];
  }, [departures]);

  const ref = useRef(null);

  useEffect(() => {
    (ref.current as HTMLElement | null)?.scrollIntoView();
  }, [routeShortName, stopPointName, serviceId]);

  return (
    <>
      <div ref={ref} className="timetable-scroll-anchor"></div>
      <Table size="sm" bordered hover className="table-td-align-middle">
        <thead>
          <tr>
            <th colSpan={2} className="text-center align-middle px-1 py-2">
              <span className="route-short-name">{routeShortName}</span>
              <br />
              {isAgglomerationRoute(routeShortName) && (
                <span className="agglomeration-route nobr text-success text-uppercase">
                  Linia aglomeracyjna
                </span>
              )}
            </th>
            <th className="w-75 fs-5 text-decoration-underline align-top">
              {stopPointName}
            </th>
          </tr>
        </thead>
        <tbody>
          {range(minHour, maxHour).map((hour) => (
            <tr key={hour}>
              <td className="timetable-hour bold text-center px-2">
                {hour % 24}
              </td>
              <td
                key={serviceId}
                colSpan={2}
                className="timetable-minutes px-2"
              >
                {departures
                  .filter((departure) => getHour(departure) === hour)
                  .map((departure, i) => (
                    <React.Fragment key={i}>
                      {i !== 0 && <>&ensp;</>}
                      <Link
                        to={`/blocks/${departure.category}/${departure.service_id}/${departure.block_id}/trips/${departure.trip_id}`}
                        className={
                          "hidden-link" +
                          (departure.time ? " bg-info bg-opacity-25" : "") +
                          (departure.hidden
                            ? " bg-secondary bg-opacity-25"
                            : "")
                        }
                      >
                        {getMinute(departure)}
                      </Link>
                      {departure.arrival && (
                        <>
                          <Arrival />
                          &ensp;
                        </>
                      )}
                      {departure.time && (
                        <sup>
                          <Countdown timestamp={departure.time} />
                        </sup>
                      )}
                    </React.Fragment>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} className="pb-5">
              Zakłócenia w ruchu powodują zmiany czasów odjazdów.
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}
