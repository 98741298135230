import { Fragment } from "react";
import { History } from "../hooks/useVehicleHistory";
import { RouteShortNameBox } from "./RouteShortNameBox";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

function formatDate(date: string) {
  return new Date(date).toLocaleDateString();
}

interface VehicleHistoryTableProps {
  history: History[];
  loading: boolean;
}

export function VehicleHistoryTable({
  history,
  loading,
}: VehicleHistoryTableProps) {
  return (
    <Table size="sm" striped bordered hover className="table-td-align-middle">
      <thead>
        <tr>
          <th style={{ width: "50%" }} className="text-center">
            Data &uarr;
          </th>
          <th className="text-center">Linie</th>
        </tr>
      </thead>
      <tbody>
        {history.map((item) => (
          <tr key={item.date}>
            <td className="text-center">{formatDate(item.date)}</td>
            <td className="text-center">
              {item.route_short_names.map((route_short_name) => (
                <Fragment key={route_short_name}>
                  <RouteShortNameBox routeShortName={route_short_name} />{" "}
                </Fragment>
              ))}
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={2} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && history.length === 0 && (
          <tr>
            <td colSpan={2} className="py-3 text-center text-secondary">
              Brak historii.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
