import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export type Status = "ok" | "warning" | "error";

interface Response {
  status: Status;
  message?: string;
}

export function useStatus(service: string) {
  const { data, error } = useFetch<Response>(
    `${BACKEND_BASE_URL}/api/status/${service}`
  );

  const [response, setResponse] = useState<Response>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setResponse(data);
      setLoading(false);
    }
  }, [data]);

  return { response, loading, error };
}
