import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Floor } from "../hooks/useVehicle";

interface FloorIconProps {
  floor: Floor | null;
}

export function FloorIcon({ floor }: FloorIconProps) {
  const [icon, text] =
    floor === "low_floor"
      ? ["♿", "Niskopodłogowy"]
      : floor === "low_entry"
      ? ["♿*", "Niskowejściowy"]
      : ["🪜", "Wysokopodłogowy"];

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
      <span>{icon}</span>
    </OverlayTrigger>
  );
}
