function reload() {
  window.location.reload();
}

export function ReloadLink() {
  return (
    <span
      className="link-primary text-decoration-underline"
      role="button"
      onClick={reload}
    >
      Spróbuj ponownie
    </span>
  );
}
