import { Link, useParams } from "react-router-dom";
import { expandDepotName, isValidCategory } from "../utils";

import Button from "react-bootstrap/Button";
import { Category } from "../hooks/useBlock";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { TripStopTimes } from "../components/TripStopTimes";
import { useTrip } from "../hooks/useTrip";
import Alert from "react-bootstrap/Alert";

interface TripInnerProps {
  category: Category;
  serviceId: string;
  blockId: string;
  tripId: string;
}

function TripInner({ category, serviceId, blockId, tripId }: TripInnerProps) {
  const {
    routeShortName,
    tripHeadsign,
    stopTimes,
    prevTripId,
    nextTripId,
    loading,
    error,
  } = useTrip(category, serviceId, blockId, tripId);

  if (!loading && stopTimes.length === 0) {
    return (
      <Container>
        <h1 className="my-4">Rozkład kursu</h1>
        <Container className="g-sm-0">
          <Alert variant="secondary">Nie znaleziono kursu.</Alert>
        </Container>
      </Container>
    );
  }

  return (
    <Container>
      {tripHeadsign !== undefined && (
        <PageTitle
          title={`${routeShortName} → ${expandDepotName(tripHeadsign)}`}
        />
      )}
      <h1 className="my-4">
        {tripHeadsign !== undefined && (
          <>
            {routeShortName} &rarr; {expandDepotName(tripHeadsign)}
          </>
        )}
      </h1>
      <div className="col-lg-6 mb-4">
        {error ? (
          <Error error={error} />
        ) : loading ? (
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <div className="mb-3">
              {prevTripId !== null ? (
                <Link
                  to={`/blocks/${category}/${serviceId}/${blockId}/trips/${prevTripId}`}
                  className="me-2"
                  replace
                >
                  <Button variant="outline-primary" className="mb-2">
                    &larr; Poprzedni kurs
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="outline-secondary"
                  className="me-2 mb-2"
                  disabled
                >
                  &larr; Poprzedni kurs
                </Button>
              )}
              <Link
                to={`/blocks/${category}/${serviceId}/${blockId}`}
                className="me-2"
              >
                <Button variant="outline-primary" className="mb-2">
                  Rozkład brygady
                </Button>
              </Link>
              {nextTripId !== null ? (
                <Link
                  to={`/blocks/${category}/${serviceId}/${blockId}/trips/${nextTripId}`}
                  replace
                >
                  <Button variant="outline-primary" className="mb-2">
                    Następny kurs &rarr;
                  </Button>
                </Link>
              ) : (
                <Button variant="outline-secondary" className="mb-2" disabled>
                  Następny kurs &rarr;
                </Button>
              )}
            </div>
            <TripStopTimes stopTimes={stopTimes} />
          </>
        )}
      </div>
    </Container>
  );
}

export function Trip() {
  const { category, serviceId, blockId, tripId } = useParams();

  if (category === undefined || !isValidCategory(category)) {
    return null;
  }
  if (serviceId === undefined) {
    return null;
  }
  if (blockId === undefined) {
    return null;
  }
  if (tripId === undefined) {
    return null;
  }

  return (
    <TripInner
      category={category as Category}
      serviceId={serviceId}
      blockId={blockId}
      tripId={tripId}
    />
  );
}
