import { Block, Service, StopPoint, useRoute } from "../hooks/useRoute";
import { Link, useParams } from "react-router-dom";
import { decodeStopName, encodeStopName, isInteger } from "../utils";
import { useMemo, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { Timetable } from "../components/Timetable";
import { useDepartures } from "../hooks/useDepartures";

interface DeparturesInnerInnerInnerProps {
  routeShortName: string;
  directionId: number;
  stopPointName: string;
  serviceId: string;
}

function DeparturesInnerInnerInner({
  routeShortName,
  directionId,
  stopPointName,
  serviceId,
}: DeparturesInnerInnerInnerProps) {
  const decodedStopPointName = decodeStopName(stopPointName);

  const { departures, loading, error } = useDepartures(
    routeShortName,
    directionId,
    decodedStopPointName
  );

  const filteredDepartures = useMemo(() => {
    return departures.filter((departure) => departure.service_id === serviceId);
  }, [departures, serviceId]);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (filteredDepartures.length === 0) {
    return (
      <Alert variant="secondary">Brak odjazdów w wybrany dzień tygodnia.</Alert>
    );
  }

  return (
    <Timetable
      routeShortName={routeShortName}
      stopPointName={decodedStopPointName}
      departures={filteredDepartures}
      serviceId={serviceId}
    />
  );
}

interface StopPointsListProps {
  stopPoints: StopPoint[];
  stopPointName: string | null;
  routeShortName: string;
  directionId: number;
}

function StopPointsList({
  stopPoints,
  stopPointName,
  routeShortName,
  directionId,
}: StopPointsListProps) {
  const decodedStopPointName = stopPointName && decodeStopName(stopPointName);

  return (
    <ul className="list-unstyled mt-3">
      {stopPoints.map((stopPoint) => (
        <li
          key={stopPoint.stop_point_id}
          className={
            stopPoint.stop_point_name === decodedStopPointName
              ? "bold"
              : undefined
          }
        >
          <Link
            to={`/routes/${routeShortName}/directions/${directionId}/stops/${encodeStopName(
              stopPoint.stop_point_name
            )}/departures`}
            replace
          >
            {stopPoint.stop_point_name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

interface DeparturesInnerInnerProps {
  routeShortName: string;
  directionId: number;
  stopPointName: string | null;
  stopPoints: StopPoint[];
  services: Service[];
  blocks: Block[];
}

function isOperating(services: Service[], serviceId: string) {
  return !!services.find((service) => service.service_id === serviceId)
    ?.operating;
}

function DeparturesInnerInner({
  routeShortName,
  directionId,
  stopPointName,
  stopPoints,
  services,
  blocks,
}: DeparturesInnerInnerProps) {
  const [serviceId, setServiceId] = useState<string | undefined>(
    () =>
      (
        blocks.find((block) => block.vehicles_on_block.length >= 1) ||
        blocks.find((block) => block.is_current)
      )?.service_id // any active vehicle or any current block
  );

  const filteredStopPoints = useMemo(() => {
    return stopPoints.filter(
      (stopPoint) => stopPoint.direction_id === directionId
    );
  }, [stopPoints, directionId]);

  const hasOppositeDirection = useMemo(() => {
    return stopPoints.some(
      (stopPoint) => stopPoint.direction_id !== directionId
    );
  }, [stopPoints, directionId]);

  return (
    <div className="row">
      <div className="col-xl-4 mb-3">
        <h1 className="mt-0 mb-4">Linia {routeShortName}</h1>
        <ServiceSelect
          services={services}
          serviceId={serviceId}
          setServiceId={setServiceId}
          disabledFunc={(serviceId) => !isOperating(services, serviceId)}
        />
        <div className="mt-3">
          {hasOppositeDirection ? (
            <Link
              to={`/routes/${routeShortName}/directions/${1 - directionId}`}
              replace
              className="btn btn-outline-primary"
            >
              &#8635; Przeciwny kierunek
            </Link>
          ) : (
            <span className="btn btn-outline-secondary disabled">
              &#8602; Linia jednokierunkowa
            </span>
          )}
        </div>
        <StopPointsList
          stopPoints={filteredStopPoints}
          routeShortName={routeShortName}
          directionId={directionId}
          stopPointName={stopPointName}
        />
      </div>
      <div className="col-xl-6 mb-3">
        {serviceId === undefined ? (
          <Alert variant="secondary">Wybierz dzień tygodnia.</Alert>
        ) : stopPointName === null ? (
          <Alert variant="secondary">Wybierz przystanek.</Alert>
        ) : (
          <DeparturesInnerInnerInner
            routeShortName={routeShortName}
            directionId={directionId}
            stopPointName={stopPointName}
            serviceId={serviceId}
          />
        )}
      </div>
    </div>
  );
}

interface DeparturesInnerProps {
  routeShortName: string;
  directionId: number;
  stopPointName: string | null;
}

function DeparturesInner({
  routeShortName,
  directionId,
  stopPointName,
}: DeparturesInnerProps) {
  const { stopPoints, services, blocks, loading, error } =
    useRoute(routeShortName);

  return (
    <Container className="my-4">
      <PageTitle title={`Linia ${routeShortName}`} />
      {(error || loading) && (
        <h1 className="mt-0 mb-4">Linia {routeShortName}</h1>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <DeparturesInnerInner
          routeShortName={routeShortName}
          directionId={directionId}
          stopPointName={stopPointName}
          stopPoints={stopPoints}
          services={services}
          blocks={blocks}
        />
      )}
    </Container>
  );
}

export function Departures() {
  const { routeShortName, directionId, stopPointName } = useParams();

  if (routeShortName === undefined) {
    return null;
  }
  if (directionId === undefined || !isInteger(directionId)) {
    return null;
  }

  return (
    <DeparturesInner
      routeShortName={routeShortName}
      directionId={Number.parseInt(directionId)}
      stopPointName={stopPointName ?? null}
    />
  );
}
