import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useCacheBuster } from "./useCacheBuster";
import { useFetch } from "usehooks-ts";

export type Category = "tram" | "bus" | "mobilis";

export interface CurrentStopTime {
  category: Category;
  service_id: string;
  block_id: string;
  trip_id: string;
  route_short_name: string;
  trip_headsign: string;
  arrival: boolean;
  hidden: boolean;
  old: boolean;
  live: boolean;
  planned_departure_time: string;
  planned_departure_timestamp: number;
  predicted_departure_timestamp: number | null;
  predicted_delay: number | null;
  kmk_id: string | null;
}

export function useCurrentStopTimes(stopName: string, interval: number) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{ current_stop_times: CurrentStopTime[] }>(
    `${BACKEND_BASE_URL}/api/stops/${stopName}/current_stop_times?t=${cacheBuster}`
  );

  const [currentStopTimes, setCurrentStopTimes] = useState<CurrentStopTime[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setCurrentStopTimes(data.current_stop_times);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when stop name changes
    setCurrentStopTimes([]);
    setLoading(true);
  }, [stopName]);

  return { currentStopTimes, loading, error };
}
