import { Category, useBlock } from "../hooks/useBlock";
import { isValidCategory } from "../utils";

import { BlockTrips } from "../components/BlockTrips";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

interface BlockInnerProps {
  category: Category;
  serviceId: string;
  blockId: string;
}

function BlockInner({ category, serviceId, blockId }: BlockInnerProps) {
  const { trips, loading, error } = useBlock(category, serviceId, blockId);

  return (
    <Container>
      <PageTitle title="Rozkład brygady" />
      <div className="col-xl-10">
        <h1 className="my-4">Rozkład brygady</h1>
        {error?.message === "NOT FOUND" ? (
          <Container className="g-sm-0">
            <Alert variant="secondary">Nie znaleziono brygady.</Alert>
          </Container>
        ) : error ? (
          <Error error={error} />
        ) : (
          <BlockTrips
            category={category}
            serviceId={serviceId}
            blockId={blockId}
            trips={trips}
            loading={loading}
          />
        )}
      </div>
    </Container>
  );
}

export function Block() {
  const { category, serviceId, blockId } = useParams();

  if (category === undefined || !isValidCategory(category)) {
    return null;
  }
  if (serviceId === undefined) {
    return null;
  }
  if (blockId === undefined) {
    return null;
  }

  return (
    <BlockInner
      category={category as Category}
      serviceId={serviceId}
      blockId={blockId}
    />
  );
}
