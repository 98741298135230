import "./Arrival.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export function Arrival() {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Przyjazd</Tooltip>}>
      <div className="arrival">p</div>
    </OverlayTrigger>
  );
}
