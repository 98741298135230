import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export interface StopPoint {
  stop_id: number;
  stop_point_id: number;
  stop_point_name: string;
  stop_point_lat: number | null;
  stop_point_lon: number | null;
  stop_point_num: number;
  category: string;
}

export function useStopPoints(stopName: string) {
  const { data, error } = useFetch<{
    stop_points: StopPoint[];
  }>(`${BACKEND_BASE_URL}/api/stops/${stopName}/points`);

  const [stopPoints, setStopPoints] = useState<StopPoint[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStopPoints(data.stop_points);
      setLoading(false);
    }
  }, [data]);

  return { stopPoints, loading, error };
}
